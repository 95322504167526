.google-maps {
    position: relative;
    padding-bottom: 50%; 
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
.cta .cta-inner:before {
    pointer-events: none;
}

#mainNav .navbar-brand{
    color:#DDD;
    font-size: 1.45rem;
    white-space: normal;
}

@media (min-width: 576px) {
    .img-fluid {
        width: 690px;
    }
}

@media (min-width: 768px) {
    .img-fluid {
        width: 690px;
    }
}

@media (min-width: 992px) {
    .img-fluid {
        height: auto;
    }
}

.site-heading-lower {
    color: #391f4b !important;
    color: white !important;
    font-weight: 500 !important;
}

#mainNav .navbar-nav .nav-item .nav-link {
    color: rgb(204, 227, 244) !important;
    font-size: 1.8rem;
}